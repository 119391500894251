<template>
  <div class="col-sm-12 px-0">
    <div class="row py-2">
      <div class="col-sm-2 px-0" v-if="false">
        <date-picker
          id="filtroFecha"
          v-model="datepicker"
          class="col-sm-12 px-0"
          lang="es"
          type="week"
          placeholder="Semana del año"
        ></date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 px-0">
        <b-card class="pt-3 pb-1 js" no-body>
          <div class="calendar-scroll">
          <div class="cd-schedule cd-schedule--loading margin-top-lg margin-bottom-lg js-cd-schedule">
            <div class="cd-schedule__timeline">
              <ul>
                <li v-for="(timeSlot, index) in timeSlots" :key="index">
                  <span>{{ timeSlot }}</span>
                </li>
              </ul>
            </div> <!-- .cd-schedule__timeline -->
            <div class="cd-schedule__events">
              <ul>
                <li v-for="(day, dayIndex) in diasCabecera" :key="dayIndex" class="cd-schedule__group">
                  <div class="cd-schedule__top-info sticky-header">
                    <div class="cd-schedule__top-info-content">
                      <div class="col d-flex flex-column align-items-center justify-content-center">
                        <div class="row">
                          <label class="h4 mb-0 text-center">{{ day.nombre }}</label>
                        </div>
                        <div class="row">
                          <label :class="{'current-day-circle': day.active, 'h4 font-weight-bold text-center': true}">
                            {{ day.diaMes }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="cd-schedule__top-info-line" :style="{ backgroundColor: getBackgroundColor(day) }"></div>
                  </div>

                  <ul>
                    <li
                        v-for="(event, eventIndex) in day.cita"
                        :key="eventIndex"
                        class="cd-schedule__event"
                        :style="getEventStyle(event)"
                        @click="handleCardClick(event)"
                      >
                        <a
                          :data-start="event.hora_inicio_turno || 'N/A'"
                          :data-end="event.hora_fin_turno || 'N/A'"
                          :data-content="event.content || 'No content'"
                          :data-event="event.medico_id">
                          {{ event.paciente }}
                        </a>
                      </li>
                  </ul>
                </li>
              </ul>
            </div> <!-- .cd-schedule__events -->
          </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapState } from 'vuex';

moment.locale('es');
export default {
  name: 'Calendar',
  components: {
    DatePicker,
  },
  data() {
    return {
      showModal: false,
      medicos: [],
      datepicker: moment().startOf('week').toDate(),
      diasCabecera: [],
      fechaInicio: null,
      fechaFinal: null,
      timeSlots: this.generateTimeSlots(),
      appointments: [],
      currentPage: 1,
    };
  },
  created() {
    this.$parent.$on('avanzar-semana', this.avanzarSemana);
    this.$parent.$on('retroceder-semana', this.retrocederSemana);
    this.diasCabecera = this.getDiasCabecera();
    this.appointments = [];
  },
  methods: {
    avanzarSemana() {
      this.datepicker = moment(this.datepicker).add(1, 'weeks').toDate();
      this.diasCabecera = this.getDiasCabecera();
    },
    retrocederSemana() {
      this.datepicker = moment(this.datepicker).subtract(1, 'weeks').toDate();
      this.diasCabecera = this.getDiasCabecera();
    },
    getDiasCabecera() {
      const startOfWeek = moment(this.datepicker).startOf('week');
      const days = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 7; i++) {
        const currentDay = moment(startOfWeek).add(i, 'days');
        const dayFormatted = currentDay.format('YYYY-MM-DD');

        // Verificar si hay citas en esta fecha y asignar un color
        const appointmentsForDay = this.appointments.filter((appointment) => {
          const formattedAppointmentDate = moment(appointment.fecha_turno, 'DD/MM/YYYY').format('YYYY-MM-DD');
          return moment(formattedAppointmentDate).isSame(dayFormatted, 'day') && appointment.medico_id === this.medicoActual.id;
        });

        days.push({
          nombre: currentDay.format('ddd').toUpperCase(),
          diaMes: currentDay.date(),
          fecha: dayFormatted,
          active: currentDay.isSame(moment(), 'day'),
          cita: appointmentsForDay,
        });
      }
      return days;
    },
    setPage(page) {
      this.currentPage = page;
    },
    generateTimeSlots() {
      const slots = [];
      const startHour = 0;
      const endHour = 24;
      // eslint-disable-next-line no-plusplus
      for (let hour = startHour; hour < endHour; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const formattedTime = moment().hour(hour).minute(minute).format('HH:mm');
          slots.push(formattedTime);
        }
      }
      return slots;
    },
    getScheduleTimestamp(time) {
      if (!time) {
        return 0; // Valor por defecto
      }
      const cleanTime = time.replace(/ /g, '');
      const timeArray = cleanTime.split(':');
      // eslint-disable-next-line radix
      const timeStamp = parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
      return timeStamp;
    },
    getEventStyle(event) {
      const topInfoElement = document.getElementsByClassName('cd-schedule__top-info')[0];
      if (!topInfoElement) {
        return {};
      }
      const slotHeight = topInfoElement.offsetHeight;
      const start = this.getScheduleTimestamp(event.hora_inicio_turno);
      const end = this.getScheduleTimestamp(event.hora_fin_turno);
      const duration = end - start;
      const timelineStart = this.getScheduleTimestamp('00:00');
      const timelineUnitDuration = 30;
      // eslint-disable-next-line no-mixed-operators
      const eventTop = slotHeight * (start - timelineStart) / timelineUnitDuration;
      // eslint-disable-next-line no-mixed-operators
      const eventHeight = slotHeight * duration / timelineUnitDuration;

      return {
        top: `${eventTop}px`,
        height: `${eventHeight}px`,
        backgroundColor: event.color ? event.color : 'transparent',
      };
    },
    getBackgroundColor(day) {
      const hasNullTimes = day.cita.some((event) => event.hora_inicio_turno === null && event.hora_fin_turno === null);
      const eventWithNullTimes = day.cita.find((event) => event.hora_inicio_turno === null && event.hora_fin_turno === null);
      return hasNullTimes ? eventWithNullTimes.color : '';
    },
    handleCardClick(event) {
      this.$emit('cardSelected', event);
    },
    handleCellClick() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedDia = null;
      this.selectedTimeSlot = null;
    },
    async getCitaCalendario() {
      try {
        const REQUEST = {
          fecha_inicio: this.fechaInicio,
          fecha_fin: this.fechaFinal,
          medico_id: this.medicoActual.id,
        };
        const RESPONSE = await axios.get('clinic/medico/citas_calendar', {
          params: REQUEST,
        });
        this.appointments = RESPONSE.data.data.medicos_citas_calendario;
        this.diasCabecera = this.getDiasCabecera(); // Asegúrate de actualizar la cabecera después de establecer los colores
      } catch (error) {
        console.error('Error fetching appointments:', error);
      }
    },
  },
  computed: {
    ...mapState('TheBannerMedico', [
      'medicoActual',
    ]),
    paginatedTimeSlots() {
      const morningSlots = this.timeSlots.slice(0, 10); // 7 AM - 12 PM (10 slots)
      const afternoonSlots = this.timeSlots.slice(10);// 12 PM - 7 PM (10 slots)

      return this.currentPage === 1 ? morningSlots : afternoonSlots;
    },
    getActualRangeWeek() {
      const initDay = moment(this.datepicker).format('DD');
      const initMonth = moment(this.datepicker).locale('es').format('MMMM');
      const initYear = moment(this.datepicker).format('YYYY');
      const endDate = moment(this.datepicker).add(6, 'days').toDate();
      const endDateDay = moment(endDate).format('DD');
      const endDateMonth = moment(endDate).locale('es').format('MMMM');
      const endDateYear = moment(endDate).format('YYYY');
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.fechaInicio = moment(this.datepicker).format('YYYY-MM-DD');
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.fechaFinal = moment(this.datepicker).add(6, 'days').format('YYYY-MM-DD');
      if (this.datepicker !== null) {
        if (initYear === endDateYear) {
          return `${initDay} - ${endDateDay} ${initMonth} ${initYear}`;
        }
        return `${initDay} ${initMonth} ${initYear} - ${endDateDay} ${endDateMonth} ${endDateYear}`;
      }
      return '';
    },
  },
  watch: {
    medicoActual: {
      immediate: true, // Opcional: para llamar a la función también cuando el componente se monta
      handler(newMedico) {
        if (newMedico) {
          this.getCitaCalendario();
        }
      },
    },
    getActualRangeWeek() {
      if (this.fechaInicio !== 'Invalid Date' || this.fechaInicio !== ''
        || this.fechaFinal !== 'Invalid Date' || this.fechaFinal !== '') {
        this.$emit('citaSemanal', this.getActualRangeWeek, this.fechaInicio, this.fechaFinal);
      }
    },
  },
};
</script>

<style scoped>
.custom-rounded-left{
  border-radius: 0.25rem 0 0 0.25rem;
}
.custom-rounded-right{
  border-radius: 0 0.25rem 0.25rem 0;
}
.calendar-scroll {
  height: 658px;
  overflow-y: auto; /* Cambiado a auto para mostrar la barra de desplazamiento si es necesario */
  scrollbar-width: none;
}
.calendar-scroll::-webkit-scrollbar {
  display: none;
}
.sticky-header {
  position: sticky;
  top: 0; /* Mantiene la cabecera en la parte superior del contenedor cuando se hace scroll */
  z-index: 1000; /* Asegura que la cabecera esté por encima de los eventos */
  background-color: white; /* Fondo blanco para que no se mezcle con el contenido detrás */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Sombra para separar visualmente la cabecera */
  padding: 10px 0;
}
.current-day-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #007bff; /* Cambia el color según tus preferencias */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em; /* Ajusta el tamaño del texto si es necesario */
  font-weight: bold;
}
:root {
  --schedule-rows-number: 48;
  --schedule-rows-height: 60px;
}

.js .cd-schedule {
  width: calc(100% - 1.25em);
  margin-left: auto;
  margin-right: auto;
  max-width: 90rem;
  position: relative;
 }
 .js .cd-schedule__timeline {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: 50px;
}
ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
}
.js .cd-schedule__timeline li {
  position: relative;
  height: 60px;
}
.js .cd-schedule__timeline li::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: hsl(0, 0%, 92%);
}
.js .cd-schedule__timeline li span {
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0.83333em;
  font-size: var(--text-sm);
}
.js .cd-schedule__timeline li span {
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.js .cd-schedule__events {
  width: calc(100% - 60px);
  margin-left: 60px;
}
.cd-schedule__events {
  position: relative;
  z-index: 1;
}
.js .cd-schedule__events>ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.js .cd-schedule__group {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border: 1px solid hsl(0, 0%, 92%);
  margin-bottom: 0;
}
.js .cd-schedule__top-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 60px;
  border-bottom: 1px solid hsl(0, 1%, 68%);
  padding: 0;
}
.cd-schedule__top-info {
  width: 100%;
  padding: 0 1.25em;
}
.cd-schedule__top-info-content {
  position: relative;
  z-index: 1; /* Ensure content is above the line */
}

.cd-schedule__top-info-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px; /* Adjust height to your preference */
  z-index: 0; /* Ensure the line is behind the content */
}

.js .cd-schedule__top-info>span {
  font-weight: normal;
  font-size: 0.83333em;
  font-size: var(--text-sm);
  margin-bottom: 0;
}
.cd-schedule__top-info>span {
display: inline-block;
margin-bottom: 0.75em;
font-weight: bold;
}
.js .cd-schedule__group>ul {
  height: calc(60px * 48);
  overflow: visible;
  padding: 0;
}
.cd-schedule__group>ul {
position: relative;
padding: 0 1.25em;
display: -ms-flexbox;
display: flex;
overflow-x: scroll;
-webkit-overflow-scrolling: touch;
}
.js .cd-schedule__event {
  position: absolute;
  z-index: 3;
  width: calc(100% + 2px);
  left: -1px;
  max-width: none;
  margin-right: 0;
}
.cd-schedule__event {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  float: left;
  height: 150px;
  width: 70%;
  max-width: 300px;
  margin-right: 1.25em;
  margin-right: var(--space-md);
  transition: opacity .2s, background .2s;
}
.js .cd-schedule__event a {
  text-align: center;
  color: white;
  padding: 0.75em;
  padding: var(--space-sm);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), inset 0 -3px 0 rgba(0, 0, 0, 0.2);
}
.cd-schedule__event [data-event="event-1"], .cd-schedule-modal[data-event="event-1"] .cd-schedule-modal__header-bg {
  background: hsl(199, 25%, 46%);
  background: var(--cd-color-event-1);
}
.cd-schedule__event a {
  display: block;
  height: 100%;
  padding: 0.75em;
  padding: var(--space-sm);
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
a {
  color: hsl(162, 14%, 68%);
}
a, .link {
  color: hsl(220, 90%, 56%);

  text-decoration: underline;
}
.cd-schedule-modal__date, .cd-schedule__event a::before {
  opacity: .7;
  margin-bottom: 0.25em;
}
</style>
