<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div class="row" v-else>
    <div class="col-sm-8">
      <div class="row">
        <BannerMedico />
      </div>
      <div class="col-sm-12 px-0">
        <Calendar
        @avanzar-semana="avanzarSemana"
        @retroceder-semana="retrocederSemana"
        @cardSelected="onCardSelected"
        @citaSemanal="citaSemanal"/>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="row pl-4">
        <div class="col-sm-12">
          <div class="row mb-1">
           <div class="col-sm-4">
              <button
          class="btn btn-outline-secondary py-1 px-3
            custom-rounded-left border-right-0"
          @click="retrocederSemana"
        >
          <svg
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="chevron left"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-chevron-left b-icon bi"
          >
            <g transform="translate(0 -0.5)">
              <g>
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                </path>
              </g>
            </g>
          </svg>
        </button>
        <button
          class="btn btn-outline-secondary py-1 px-3 custom-rounded-right"
          @click="avanzarSemana"
        >
          <svg
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="chevron left"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-chevron-left b-icon bi"
          >
            <g transform="translate(0 -0.5)">
              <g transform="translate(8 8) scale(-1 1) translate(-8 -8)">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                </path>
              </g>
            </g>
          </svg>
        </button>
           </div>
            <div class="col-sm-8 text-center">
              <button
                class="btn btn-warning col-sm-12 pt-2 "
                style="border-radius: 12px;"
                @click="openModalCitas"
              >
                <h5 class="text-white">Nueva Reserva</h5>
              </button>
              <ModalProgramarCita
              :getMedicos='medicos'
              @cita-agregada="citasDelDia"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b-card no-body class="py-3 px-3">
                <div class="row pl-3 pr-1">
                  <div class="col-sm-12 custom-scroll">
                    <div class="row pr-2">
                      <b-card
                        v-for="(medico,index) in medicosCitas"
                        :key="medico.index"
                        no-body
                        class="col-sm-12 py-3"
                        :class="{ 'mt-3' : index > 0 }"
                        v-bind:style="{ background: medico.color }"
                      >
                        <div class="row py-3">
                          <div class="col-sm-2 pl-2 pr-0">
                            <b-avatar :src="url + medico.logo_perfil">
                            </b-avatar>
                          </div>
                          <div class="col-sm-6 pl-0" style="color: white">
                            {{ medico.medico }}<br>
                            {{ medico.fecha_turno }} {{ medico.hora_inicio_turno }}<br>
                          </div>
                          <div class="col-sm-3 col-md-2 col-lg-1 px-1 text-right">
                            <button
                              v-if="medico.estado_cita === 'Reservado'"
                              class="btn btn-light py-1 btn-small" style="width: 100px;">
                              {{ medico.estado_cita }}
                            </button>
                            <button
                              v-else-if="medico.estado_cita === 'Pagado'"
                              class="btn btn-success py-1">
                              {{ medico.estado_cita }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="row pb-3 text-white"
                        >
                          <div class="col-sm-10 text-right pl-0">
                            {{ medico.paciente }} <br>
                            {{ medico.servicios }}
                          </div>
                          <div class="col-sm-2 px-1">
                            <b-avatar>
                            </b-avatar>
                          </div>
                        </div>
                      </b-card>
                      <ModalEstadoPaciente />
                      <b-card v-if="medicosCitas.length === 0"
                        no-body class="col-sm-12 mt-3 py-3 bg-card-color-2">
                        No tiene citas pendientes.
                      </b-card>
                     <!--  <img v-if="medicosCitas.length === 0"
                        :src="require('@/assets/images/faces/descanso.jpg')"
                        style="border-radius: 12px 0 0 12px;position: relative;"
                        class="no-selectable"
                        height="244px" width="80%" alt /> -->
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import BannerMedico from '../components/TheBannerMedico.vue';
import Calendar from '../components/TheCalendar.vue';
import ModalProgramarCita from '../components/TheModalProgramarCita.vue';
import ModalEstadoPaciente from '../components/TheModalEstadoPaciente.vue';

export default {
  name: 'index',
  components: {
    BannerMedico,
    Calendar,
    ModalProgramarCita,
    ModalEstadoPaciente,
  },
  created() {
    this.citasDelDia();
  },
  data() {
    return {
      fechaInicio: moment(),
      fechaFinal: moment(),
      medicosCitas: [],
      getActualRangeWeek: null,
      url: process.env.VUE_APP_BASE,
      medicos: [],
      isLoading: true,
      selectCard: null,
    };
  },
  methods: {
    openModalCitas() {
      this.$bvModal.show('modal-programarCita');
    },
    openModalEstadoPaciente() {
      this.$bvModal.show('modal-estadoPaciente');
    },
    async citasDelDia() {
      const data = {
        fecha_inicio: moment(this.fechaInicio).format('YYYY-MM-DD'),
        fecha_fin: moment(this.fechaFinal).format('YYYY-MM-DD'),
      };
      axios.get('/clinic/turnos-programados', { params: data })
        .then((response) => {
          this.medicosCitas = response.data.data.citas_programadas.medicos_citas;
          this.medicos = response.data.data.medicos;
          this.isLoading = false;
        })
        .catch((error) => {
          util.showNotify(error.response.data.message, 'error');
        });
    },
    citaSemanal(getActualRangeWeek, fechaInicio, fechaFinal) {
      this.fechaInicio = fechaInicio;
      this.fechaFinal = fechaFinal;
      this.getActualRangeWeek = getActualRangeWeek;
      if (fechaInicio === 'Invalid date' || fechaInicio === null
        || fechaFinal === 'Invalid date' || fechaFinal === null) {
        this.fechaInicio = moment();
        this.fechaFinal = moment();
        this.getActualRangeWeek = this.getActualRangeWeeks(this.fechaInicio);
      }
      this.citasDelDia();
    },
    getActualRangeWeeks(fechaInicio) {
      const initDay = moment(fechaInicio).format('DD');
      const initMonth = moment(fechaInicio).locale('es').format('MMMM');
      const initYear = moment(fechaInicio).format('YYYY');
      const endDate = moment(fechaInicio).add(6, 'days').toDate();
      const endDateDay = moment(endDate).format('DD');
      const endDateMonth = moment(endDate).format('MMMM');
      const endDateYear = moment(endDate).format('YYYY');
      if (fechaInicio !== null) {
        if (initYear === endDateYear) {
          return `${initDay} - ${endDateDay} ${initMonth} ${initYear}`;
        }
        return `${initDay} ${initMonth} ${initYear} - ${endDateDay} ${endDateMonth} ${endDateYear}`;
      }
      return '';
    },
    onCardSelected(card) {
      this.selectCard = card;
    },
    avanzarSemana() {
      this.$emit('avanzar-semana');
    },
    retrocederSemana() {
      this.$emit('retroceder-semana');
    },
  },
};
</script>

<style scoped>
.bg-card-color-1 {
  background: #d3cbfa;
}
.bg-card-color-2 {
  background: #ffd3d4;
}
.bg-card-color-3 {
  background: #bfeff3;
}
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.btn-skyblue:active {
  box-shadow: 0 0 0 0.2rem rgba(67, 179, 228, 0.5) !important;
  background-color: rgb(52, 143, 212);
}
.btn-skyblue:focus {
  box-shadow: 0 8px 25px -8px rgba(67, 179, 228, 0.6);
}
.text-skyblue
{
  color: #61c6f2;
}
.search-bar {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #fff ;;
  border: 1px solid gray;
  border-radius: 20px;
  position: relative;
  width: 100%;
  height: 40px;
}
.search-bar input {
  background: transparent;
  border: 0;
  color: #212121;
  font-size: 0.8rem;
  line-height: 2;
  height: 100%;
  outline: initial !important;
  padding: 0.5rem 1rem;
  width: calc(100% - 32px);
}
.search-bar.search-icon {
  font-size: 18px;
  width: 24px;
  display: inline-block;
}
.custom-scroll {
  height: 860px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #6f55dd #f0f0f0;
}
.custom-scroll::-webkit-scrollbar {
  width: 5px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #6f55dd;
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 10px;
}
.btn-small {
  font-size: 0.75rem;
}
</style>
