<template>
  <div class="col-sm-12 bg-skyblue custom-round">
    <div class="row" style="position:absolute;z-index:1;width:100%">
      <div class="col-sm-4 offset-sm-8 text-right pt-2">
        <button
          class="btn btn-outline-secondary py-1 px-3
            custom-rounded-left border-right-0"
          @click="prevSlider"
        >
          <svg
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="chevron left"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-chevron-left b-icon bi"
          >
            <g transform="translate(0 -0.5)">
              <g>
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                </path>
              </g>
            </g>
          </svg>
        </button>
        <button
          class="btn btn-outline-secondary py-1 px-3 custom-rounded-right"
          @click="nextSlider"
        >
          <svg
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="chevron left"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-chevron-left b-icon bi"
          >
            <g transform="translate(0 -0.5)">
              <g transform="translate(8 8) scale(-1 1) translate(-8 -8)">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                </path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <b-carousel
      id="carouselMedico"
      ref="carouselMedico"
      :interval="0"
      v-model="slide"
      background="transparent"
    >
      <div v-for="(medico, index) in medicos"
      v-bind:item="medico"
      v-bind:index="index"
      v-bind:key="index">
      <b-carousel-slide>
        <template #img>
          <div class="row bg-skyblue">
            <div class="col-sm-5 px-0 custom-oval">
              <div class="img-container">
                <img
                  v-if="medico.logo_perfil"
                  :src="url + medico.logo_perfil"
                  class="img-profile"
                  alt="Perfil"
                />
                <img
                  v-else
                  src="@/assets/images/faces/img_default.png"
                  class="img-default"
                  alt="Por defecto"
                />
                <img
                  src="@/assets/images/contorno.png"
                  class="img-contorno"
                  alt="Contorno"
                />
              </div>
            </div>
            <div class="col-sm-7">
              <div class="row"><div class="col-sm-12 pt-3 pb-4"></div></div>
              <div class="row">
                <div class="col-sm-9 text-right px-0">
                  <input type="hidden" v-model="posicion">
                  <span
                    class="text-white"
                    :class="medico.nombre.length > 32 ? 'h4': 'h3'"
                  > {{medico.nombre}} </span>
                </div>
              </div>
              <div class="mt-2">
                <div class="row mb-2">
                  <div class="col-sm-8 offset-sm-3 pr-0">
                    <b-card no-body class="py-2 pl-3">
                      <div class="row mx-0">
                        <div class="col-sm-9 left-line-success pt-1">
                          <!-- <div class="align-middle spinner-bubble spinner-bubble-danger">
                            </div> -->
                          <span v-if="!turnos" class="text-light">Sin Trabajo</span>
                          <span v-else class="text-light">{{ cantidadCitas }}
                            Paciente Programado</span>
                        </div>
                        <div class="col-sm-3">
                          <div class="text-right">
                            <button
                              class="btn btn-block text-center px-0 py-1"
                              :class=" (cantidadCitas > 0 ) ? 'btn-success' : 'btn-warning'"
                              @click="pdfResumenDelDia(medico)"
                            >
                              Ver
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-carousel-slide>
      </div>
    </b-carousel>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'BannerMedico',
  components: {
  },
  data() {
    return {
      slide: 0,
      porPagina: 1,
      paginaActual: 1,
      url: process.env.VUE_APP_BASE,
      load: false,
      dia: parseInt(moment().format('DD'), 10),
      mes: moment().locale('es').format('MMMM'),
      total: 0,
      posicion: 0,
    };
  },
  created() {
    this.getMedicos();
  },
  methods: {
    prevSlider() {
      this.$refs.carouselMedico.prev();
      this.posicion = (this.posicion - 1 + this.medicos.length) % this.medicos.length;
      this.getTurno(this.medicos[this.posicion].id);
      this.setMedicoActual(this.medicos[this.posicion]);
    },
    nextSlider() {
      this.$refs.carouselMedico.next();
      this.posicion = (this.posicion + 1) % this.medicos.length;
      this.getTurno(this.medicos[this.posicion].id);
      this.setMedicoActual(this.medicos[this.posicion]);
    },
    nextFecha(medico) {
      const endDate = moment(this.fecha).add(1, 'days').toDate();
      const endDateDay = moment(endDate).format('DD');
      this.dia = endDateDay;
      this.mes = moment(endDate).locale('es').format('MMMM');
      this.fecha = endDate;
      this.getTurno(medico.id);
    },
    prevFecha(medico) {
      const endDate = moment(this.fecha).subtract(1, 'days').toDate();
      const endDateDay = moment(endDate).format('DD');
      this.dia = endDateDay;
      this.mes = moment(endDate).locale('es').format('MMMM');
      this.fecha = endDate;
      this.getTurno(medico.id);
    },
    verificarTurno(turnos) {
      turnos.forEach((item) => {
        if (item.entrada) {
          this.turnos = item;
        }
      });
    },
    ...mapActions('TheBannerMedico', [
      'getMedicos',
      'getTurno',
      'setMedicoActual',
      'pdfResumenDelDia',
    ]),
  },
  computed: {
    ...mapState('TheBannerMedico', [
      'medicos',
      'turnos',
      'cantidadCitas',
      'fecha',
    ]),
  },
};
</script>

<style scoped>
.patient-scroll {
  height: 125px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.patient-scroll::-webkit-scrollbar {
  display: none;
}
.no-selectable {
  -webkit-user-drag: none;
}
.bg-skyblue
{
  background: #5ac8fb !important;
}
.custom-oval {
  position: relative;
  overflow: hidden;
  border-radius: 50%; /* Hace los bordes ovalados */
}

.img-container {
  position: relative;
  width: 100%;
  height: 244px; /* Ajusta según el tamaño deseado */
  border-radius: 50%; /* Aplica el mismo radio a la imagen para mantener el formato ovalado */
}

.img-profile,
.img-default,
.img-contorno {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%; /* Asegura que las imágenes sigan la forma ovalada del contenedor */
}

.img-profile {
  /* Aquí puedes ajustar el borde si es necesario */
}

.img-default {
  transform: scale(0.8);
}

.img-contorno {
  top: 0;
  left: 0;
  border-radius: 50%; /* Asegura que el contorno también sea ovalado */
}
.custom-rounded-left{
  border-radius: 0.25rem 0 0 0.25rem;
}
.custom-rounded-right{
  border-radius: 0 0.25rem 0.25rem 0;
}
.left-line-primary {
  border-left: 3px solid #be75eb !important;
}
.left-line-success {
  border-left: 3px solid #9de3db !important;
}
</style>
