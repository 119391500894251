<template>
  <b-modal
    id="modal-estadoPaciente"
    size='md'
    title="Estado del Paciente"
    hide-footer
  >
    <div class="row mb-1">
      <div class="col-sm-3 text-right">
        <b>Paciente:</b>
      </div>
      <div class="col-sm-9 pl-0">
        Jose María
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-sm-3 text-right">
        <b>Médico:</b>
      </div>
      <div class="col-sm-9 pl-0">
        Jose
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 text-right">
        <b>Fecha:</b>
      </div>
      <div class="col-sm-9 pl-0">
        12/10/2023 - Hora 10:30
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12 text-center">
        <button class="btn btn-success mr-4 w-25">Presente</button>
        <button class="btn btn-warning mr-4 w-25">No Vino</button>
        <button class="btn btn-danger w-25">Anular</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'modalEstadoPaciente',
};
</script>
